export const useInputImageRenderer = (inputEl, previewEl) => {
  const inputImageRenderer = () => {
    const file = inputEl.value.files[0]
    const reader = new FileReader()

    reader.onloadend = function () {
      // eslint-disable-next-line no-param-reassign
      previewEl.value.src = reader.result
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }
  return {
    inputImageRenderer,
  }
}

export const _ = null
