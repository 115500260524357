<template>
  <section
    id="project-detail"
    data-app
  >
    <div class="list-view mt-0">
      <b-row class="match-height">
        <b-col cols="12">
          <b-tabs v-model="tabIndex">
            <b-tab href="#info">
              <template #title>
                <feather-icon icon="HomeIcon" />
                <span>Общая информация</span>
              </template>
              <b-overlay
                variant="white"
                :show="showProjectDetailLoading"
                spinner-variant="primary"
                blur="0"
                opacity=".75"
                rounded="sm"
              >
                <b-card
                  v-if="project != null"
                  no-body
                >
                  <b-card-header>
                    <b-card-title>
                      <h4>Проект: {{ project.name }}</h4>
                      <small
                        v-if="project != null"
                        class="text-muted"
                      >
                        Cоздан {{ formatDate(project.createdAt, { month: '2-digit', day: '2-digit', year: '2-digit' }) }}
                      </small>
                    </b-card-title>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      size="sm"
                      class=""
                      @click="removeProject"
                    >
                      Удалить проект
                    </b-button>
                  </b-card-header>
                  <b-card-body>
                    <b-media no-body>
                      <b-media-aside>
                        <b-link>
                          <b-img
                            ref="previewEl"
                            style="max-width: 120px;"
                            rounded
                            :src="project != null && project.avatarPath != null
                              ? `data:image/png;base64,${project.avatarPath}`
                              : avatarPathDefault"
                          />
                        </b-link>
                        <!--/ avatar -->
                      </b-media-aside>

                      <b-media-body class="mt-75 ml-75">
                        <!-- upload button -->
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-secondary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="$refs.refInputEl.$el.click()"
                        >
                          Загрузить
                        </b-button>
                        <b-form-file
                          ref="refInputEl"
                          v-model="avatarModel"
                          accept=".jpg, .png, .gif"
                          :hidden="true"
                          plain
                          @input="inputImageRenderer"
                        />
                        <!--/ upload button -->

                        <!-- reset -->
                        <b-button
                          v-if="avatarModel != null"
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="primary"
                          size="sm"
                          class="mb-75 mr-75"
                          @click="saveAvatar()"
                        >
                          Сохранить
                        </b-button>
                        <!--/ reset -->
                        <b-card-text>JPG, GIF или PNG</b-card-text>
                      </b-media-body>
                    </b-media>
                    <!--/ media -->

                    <!-- form -->
                    <b-form class="mt-2">
                      <b-row>
                        <b-col sm="6">
                          <b-form-group
                            label="Название"
                            label-for="account-username"
                          >
                            <transition-group
                              name="list"
                            >
                              <div :key="1">
                                <div style="display: inline-flex">
                                  <h5 v-if="!isShowNameRow">
                                    {{ project.name }}
                                  </h5>
                                  <b-link
                                    style="text-decoration: dotted underline;
                                text-underline-offset: 2px;"
                                    :class="['mb-1', { 'ml-1': !isShowNameRow }]"
                                    @click="isShowNameRow = !isShowNameRow"
                                  >
                                    {{ !isShowNameRow ? 'Изменить' : 'Отмена' }}
                                  </b-link>
                                </div>
                                <b-form-input
                                  v-show="isShowNameRow"
                                  v-model="project.name"
                                  placeholder="Введите название проекта"
                                  name="username"
                                />
                                <b-button
                                  v-show="isShowNameRow"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mt-2 mr-1"
                                  @click="changeFields('name')"
                                >
                                  Сохранить
                                </b-button>

                              </div>
                            </transition-group>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group
                            label="Компания"
                            label-for="account-company"
                          >
                            <div style="display: inline-flex">
                              <h5 v-if="!isShowCompanyRow">
                                {{ project.company == null ? 'нет информации...' : project.company }}
                              </h5>
                              <b-link
                                style="text-decoration: dotted underline;
                              text-underline-offset: 3px;"
                                :class="['mb-1', { 'ml-1': !isShowCompanyRow }]"
                                @click="isShowCompanyRow = !isShowCompanyRow"
                              >
                                {{ !isShowCompanyRow ? 'Изменить' : 'Отмена' }}
                              </b-link>
                            </div>
                            <b-form-input
                              v-show="isShowCompanyRow"
                              v-model="project.company"
                              name="company"
                              placeholder="Введите название компании"
                            />
                            <b-button
                              v-show="isShowCompanyRow"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="mt-2 mr-1"
                              @click="changeFields('company')"
                            >
                              Сохранить
                            </b-button>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12">
                          <b-form-group
                            label="Описание проекта"
                            label-for="description"
                          >
                            <div style="display: inline-flex">
                              <h5 v-if="!isShowDeatilRow">
                                {{ project.description == null ? 'нет информации...' : project.description }}
                              </h5>
                              <b-link
                                style="text-decoration: dotted underline;
                                  text-underline-offset: 3px;"
                                :class="[{ 'ml-1': !isShowDeatilRow }]"
                                @click="isShowDeatilRow = !isShowDeatilRow"
                              >   {{ !isShowDeatilRow ? 'Изменить' : 'Отмена' }}
                              </b-link>
                            </div>
                            <b-form-textarea
                              v-show="isShowDeatilRow"
                              id="textarea-state"
                              v-model="project.description"
                              class="mt-1"
                              :state="text.length === 0 ? null : text.length <= 10"
                              placeholder="Не больше 120 символов"
                              rows="3"
                            />
                            <b-button
                              v-show="isShowDeatilRow"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              class="mt-2 mr-1"
                              @click="changeFields('description')"
                            >
                              Сохранить
                            </b-button>

                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-card-body>
                </b-card>
              </b-overlay>
            </b-tab>
            <b-tab href="#integrations">
              <template #title>
                <feather-icon icon="ToolIcon" />
                <span>Интегрируемые системы</span>
              </template>
              <IntegrationModule
                :analytics-integrations="analyticsIntegrations"
                :traffic-integrations="trafficIntegrations"
                :ex-integrations="exIntegrations"
                :collapse-type="collapseType"
                :for-backend="true"
                :project-id="projectId"
              />
            </b-tab>
            <b-tab href="#settings">
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span>Настройка проекта</span>
              </template>
              <b-card
                v-if="project != null"
                no-body
              >
                <b-card-header>
                  <b-card-title>
                    <h4>Настройка проекта</h4>
                  </b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-form>
                    <b-row>
                      <b-col cols="6">
                        <h6>Основная система аналитики</h6>
                        <b-card-text>
                          <span> Выберите основную систему аналитики для вашего проекта </span>
                        </b-card-text>
                        <b-col
                          style="margin-left: -13px"
                          cols="10"
                        >
                          <v-select
                            id="main-analytic-select"
                            v-model="mainAnalytic"
                            :options="analyticsOptions"
                            class="mb-1 mt-1"
                            label="text"
                            placeholder="Выберите основную систему аналитики..."
                          >
                            <template v-slot:no-options>
                              Ничего не найдено
                            </template>
                          </v-select>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            @click="changeMainSystem"
                          >
                            Сохранить
                          </b-button>
                        </b-col>
                      </b-col>
                      <b-col cols="6">
                        <h6>Ключевые показатели</h6>
                        <b-card-text>
                          <span> Выберите ключевые показатели вашего проекта </span>
                        </b-card-text>
                        <b-col
                          style="margin-left: -13px"
                          cols="10"
                        >
                          <b-form-group>
                            <b-form-tags
                              v-model="tagsValue"
                              no-outer-focus
                            >
                              <template v-slot="{ tags, disabled, addTag, removeTag }">
                                <ul
                                  v-if="tags.length > 0"
                                  class="list-inline d-inline-block mb-1"
                                >
                                  <li
                                    v-for="tag in tags"
                                    :key="tag"
                                    class="list-inline-item"
                                  >
                                    <b-form-tag
                                      :title="tag"
                                      :disabled="disabled"
                                      variant="primary"
                                      @remove="removeTag(tag)"
                                    >
                                      {{ tag }}
                                    </b-form-tag>
                                  </li>
                                </ul>

                                <b-dropdown
                                  size="sm"
                                  variant="outline-secondary"
                                  block
                                  style="z-index: 1000;"
                                  menu-class="w-100"
                                >
                                  <template v-slot:button-content>
                                    <b-icon icon="tag-fill" /> Выберите показатели
                                  </template>
                                  <b-dropdown-form @submit.stop.prevent="() => {}">
                                    <b-form-group
                                      label-for="tag-search-input"
                                      label="Поиск по показателям"
                                      label-cols-md="auto"
                                      class="mb-0"
                                      label-size="sm"
                                      :description="searchDesc"
                                      :disabled="disabled"
                                    >
                                      <b-form-input
                                        id="tag-search-input"
                                        v-model="search"
                                        type="search"
                                        size="sm"
                                        autocomplete="off"
                                      />
                                    </b-form-group>
                                  </b-dropdown-form>
                                  <b-dropdown-divider />
                                  <b-dropdown-item
                                    v-for="option in availableOptions"
                                    :key="option"
                                    @click="onOptionClick({ option, addTag })"
                                  >
                                    {{ option }}
                                  </b-dropdown-item>
                                  <b-dropdown-text v-if="availableOptions.length === 0">
                                    Не найдено показателей
                                  </b-dropdown-text>
                                </b-dropdown>
                              </template>
                            </b-form-tags>
                          </b-form-group>
                          <b-modal
                            id="modal-indicator"
                            cancel-variant="outline-secondary"
                            ok-title="Добавить"
                            cancel-title="Закрыть"
                            centered
                            title="Ключевой показатель"
                            @ok="handleOk"
                          >
                            <b-form @submit.stop.prevent="handleSubmit">
                              <b-form-group>
                                <label for="indicatorValue">Значение ({{ indicatorText }}):</label>
                                <b-form-input
                                  id="indicatorValue"
                                  v-model="indicatorValue"
                                  placeholder="Введите значение показателя"
                                />
                              </b-form-group>
                            </b-form>
                          </b-modal>
                          <b-alert
                            v-height-fade.appear
                            variant="success"
                            dismissible
                            fade
                            :show="true"
                            class="mb-1"
                          >
                            <div class="alert-body">
                              <span>Не забудьте сохранить после добавления показателей</span>
                            </div>
                          </b-alert>
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="mr-1"
                            @click="changeFields('keyIndicators')"
                          >
                            Сохранить показатели
                          </b-button>
                        </b-col>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
              <b-card-text />
            </b-tab>
            <b-tab href="#load-data">
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span>Загрузчик данных</span>
              </template>
              <b-card
                v-if="project != null"
                no-body
              >
                <b-card-header>
                  <b-card-title>
                    <h4>Загрузчик данных</h4>
                  </b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-form>
                    <b-row>
                      <b-col
                        md="6"
                        cols="12"
                      >
                        <h6>Загрузка данных</h6>
                        <b-card-text>
                          <b-link
                            v-b-modal="'job-background'"
                            style="text-decoration: dotted underline;
                                        text-underline-offset: 3px;"
                          >
                            История загрузки данных
                          </b-link>

                          <b-modal
                            :id="'job-background'"
                            size="lg"
                            ok-only
                            ok-title="Понятно"
                            centered
                            title="История загрузки данных"
                          >
                            <b-table
                              :data="backgroundHistory"
                              :paginated="true"
                              class="mt-1"
                            >
                              <b-table-column
                                v-slot="props"
                                field="rangeDateTitle"
                                label="Период загрузки"
                              >
                                {{ props.row.rangeDateTitle }}
                              </b-table-column>

                              <b-table-column
                                v-slot="props"
                                field="createdDate"
                                label="Дата запуска"
                              >
                                {{ new Date(props.row.createdDate).toLocaleDateString() }}
                              </b-table-column>

                              <b-table-column
                                v-slot="props"
                                field="lastStep"
                                label="Описание"
                              >
                                {{ props.row.lastStep }}
                              </b-table-column>
                            </b-table>
                          </b-modal>
                        </b-card-text>
                        <b-card-text>
                          <span> Выберите период загрузки данных </span>
                        </b-card-text>
                        <b-col
                          style="margin-left: -13px"
                          cols="12"
                        >
                          <b-col
                            style="margin-left: -13px; margin-bottom: 10px"
                            md="6"
                          >
                            <b-alert
                              v-height-fade.appear
                              variant="danger"
                              dismissible
                              fade
                              :show="true"
                              class="mb-0"
                            >
                              <div class="alert-body">
                                <span>Не загружайте больше 1 месяца сразу</span>
                              </div>
                            </b-alert>
                          </b-col>
                          <b-row>
                            <b-col md="6">
                              <b-form-group
                                label="Статистика за период"
                                label-for="cost"
                              >
                                <flat-pickr
                                  v-model="rangeDataFilter"
                                  style="width: 100%!important;"
                                  :config="dataPickerConfig"
                                  class="form-control flat-picker bg-transparent"
                                  placeholder="YYYY-MM-DD"
                                  @on-close="changeDatesRange"
                                />
                              </b-form-group>
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                class="mr-1"
                                :disabled="rangeDataFilter == null || rangeDataFilter == ''"
                                @click="collectStats"
                              >
                                Загрузить данные
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-col>
                      <b-col
                        md="6"
                        cols="12"
                      >
                        <h5 v-if="collectingData && message != null">
                          Этапы загрузки данных
                        </h5>
                        <transition
                          name="fade"
                          mode="out-in"
                        >
                          <div
                            v-if="collectingData && message != null"
                            id="collect-data"
                            :class="[message.isEnded ? 'bg-success text-center p-3 text-light rounded mt-1' : 'bg-primary text-center p-3 text-light rounded mt-1']"
                          >
                            <feather-icon
                              icon="UploadCloudIcon"
                              size="20"
                            />
                            <div class="mb-2">
                              {{ showPrepareDataTitle }}
                            </div>
                            <b-progress
                              min="1"
                              max="20"
                              :value="counter"
                              variant="success"
                              height="6px"
                              class="mx-n3"
                            />
                          </div>
                        </transition>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
              <b-card-text />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BOverlay,
  BProgress,
  BRow,
  BAlert,
  BTab,
  BTabs,
  BFormTags,
  BDropdown,
  BDropdownForm,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BIcon,
  BDropdownText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { formatDate } from '@core/utils/filter'

import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'
import { Russian } from 'flatpickr/dist/l10n/ru'
import flatPickr from 'vue-flatpickr-component'
import IntegrationModule from '@/views/projects/IntegrationModule.vue'

const dateNow = new Date()
const dateNowPrev = new Date(new Date().setMonth(new Date().getMonth() - 1))

// eslint-disable-next-line no-extend-native
Date.prototype.yyyymmdd = function () {
  const mm = this.getMonth() + 1
  const dd = this.getDate()

  // eslint-disable-next-line radix
  return [this.getFullYear(),
    (mm > 9 ? '' : '0') + mm,
    (dd > 9 ? '' : '0') + dd,
  ].join('')
}

vSelect.props.components.default = () => ({ OpenIndicator })
export default {
  components: {
    IntegrationModule,
    BFormTags,
    BAlert,
    BDropdown,
    BDropdownForm,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BFormTextarea,
    BTabs,
    BTab,
    BButton,
    BForm,
    BImg,
    BCardHeader,
    BCardTitle,
    BFormFile,
    BFormGroup,
    BCardBody,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BLink,
    BOverlay,
    BCardText,
    BProgress,
    // SFC
    BMedia,
    BMediaAside,
    BMediaBody,
    BIcon,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    hash: {
      type: String,
      default: () => '#info',
      required: false,
    },
  },
  data() {
    return {
      backgroundHistory: [],
      collectingData: false,
      rangeDataFilter: [dateNowPrev, dateNow],
      counter: 1,
      isEndBackground: false,
      dataPickerConfig: {
        mode: 'range',
        locale: Russian,
        maxDate: new Date(),
        dateFormat: 'd.m.Y',
      },
      showProjectDetailLoading: false,
      avatarModel: null,
      project: null,
      profileFile: null,
      avatarPath: null,
      integrations: [],
      isShowNameRow: false,
      isShowCompanyRow: false,
      isShowDeatilRow: false,
      mainAnalytic: null,
      analyticsOptions: [
        {
          value: 1,
          text: 'Yandex Metrika',
        },
        {
          value: 2,
          text: 'Google Analytics',
        },
      ],
      exIntegrations: [
        {
          id: 1,
          name: 'Google Adwords',
          description: 'Google Adwords',
          type: 'GOOGLE_ADWORDS',
          category: 'TRAFFIC',
          image: require('@/assets/images/integrations/google-ds.png'),
        },
        {
          id: 2,
          name: 'Google Analytics',
          description: 'Google Analytics',
          type: 'GOOGLE_ANALYTICS',
          category: 'ANALYTICS',
          image: require('@/assets/images/integrations/google-analytics.png'),
        },
        {
          id: 3,
          name: 'Yandex Direct',
          type: 'YANDEX_DIRECT',
          category: 'TRAFFIC',
          description: 'Yandex Direct',
          image: require('@/assets/images/integrations/yandex-direct.png'),
        },
        {
          id: 4,
          name: 'Yandex Metrika',
          type: 'YANDEX_METRIKA',
          category: 'ANALYTICS',
          description: 'Yandex Metrika',
          image: require('@/assets/images/integrations/yandex-metrika.png'),
        },
        {
          id: 5,
          name: 'VKontakte',
          type: 0,
          category: 'TRAFFIC',
          description: 'VKontakte',
          image: require('@/assets/images/integrations/vk.png'),
        },
      ],
      trafficIntegrations: [],
      analyticsIntegrations: [],
      collapseType: 'margin',
      value: '',
      statusMaps: {
        SUCCESS: 0,
        NONE: 1,
        ERROR: 2,
      },
      typeMaps: {
        YANDEX_DIRECT: 0,
        YANDEX_METRIKA: 1,
        GOOGLE_ANALYTICS: 2,
        GOOGLE_ADWORDS: 3,
        VKONTAKTE: 4,
      },
      text: '',
      maxChar: 20,
      avatarFile: null,
      projectId: null,
      avatarPathDefault: require('@/assets/images/integrations/default.png'),
      interval: null,
      job: null,
      messageReceived: null,
      options: ['Показы',
        'Клики',
        'Расходы',
        'Ctr',
        'Средняя стоимость клика',
        'Выручка (аналитика)',
        'Маржинальная прибыль (аналитика)',
        'Кол-во заказов (аналитика)',
        'Стоимость заказов (аналитика)',
        'Cr (аналитика)',
        'Roi (аналитика)',
        'Выручка (CRM)',
        'Маржинальная прибыль (CRM)',
        'Кол-во заказов (CRM)',
        'Стоимость заказов (CRM)',
        'Cr (CRM)',
        'Roi (CRM)',
      ],
      search: '',
      tagsValue: [],
      tempOption: null,
      indicatorValue: null,
      percentValues: ['Roi',
        'Ctr',
        'Cr',
        'Cr (аналитика)',
        'Roi (аналитика)'],
      rubValues: ['Расходы',
        'Средняя стоимость клика',
        'Выручка (аналитика)',
        'Маржинальная прибыль (аналитика)',
        'Стоимость заказов (аналитика)', 'Выручка',
        'Маржинальная прибыль', 'Стоимость заказов'],
      indicatorText: null,
      tabIndex: 0,
      tabs: ['#info', '#integrations', '#settings'],
      changeDates: [],
      message: null,
    }
  },
  computed: {
    projects() {
      return this.$store.state.project.projects
    },
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase()
    },
    availableOptions() {
      const { criteria } = this

      // Filter out already selected options
      const options = this.options.filter(opt => this.tagsValue.indexOf(opt) === -1)
      if (criteria) {
        // Show only options that match criteria
        return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1)
      }

      // Show all options available
      return options
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return 'Нет подходяшего ответа'
      }
      return ''
    },
    // eslint-disable-next-line vue/return-in-computed-property
    showPrepareDataTitle() {
      const prepareData = this.message
      if (prepareData != null && prepareData.projectId === this.projectId) {
        // refactoring this dump code
        if (prepareData.isEnded) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.clearInterval()
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.counter = 50
        }
        return prepareData.title
      }

      if (this.job != null && prepareData == null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isEndBackground = false
        return this.job.lastStep
      }

      return 'Пытаемся получить информацию...'
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      formatDate,
      inputImageRenderer,
    }
  },
  beforeMount() {
    this.tabIndex = this.tabs.findIndex(tab => tab === this.hash)
  },
  mounted() {
    this.$serverHub.$on('prepare-data', this.onMessageReceived)

    function getKeyByValue(obj, value) {
      return Object.keys(obj).find(key => obj[key] === value)
    }

    const { projectId } = this.$route.params
    this.projectId = projectId
    this.showProjectDetailLoading = true

    this.$store.dispatch('project/fetchProjectById', { projectId })
      .then(response => {
        this.project = response.data
        if (this.project.keyIndicators != null) {
          this.tagsValue = this.project.keyIndicators.split(',')
        }
        this.showProjectDetailLoading = false
      })

    this.$http.get(`integration/integrations?projectId=${projectId}`)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        // TO-DO: NEED refactoring
        response.data.forEach(item => {
          const typeVal = getKeyByValue(this.typeMaps, item.integrationType)
          const integrationModel = {
            id: item.id,
            status: getKeyByValue(this.statusMaps, item.integrationStatus),
            name: item.name,
            type: typeVal,
            category: this.exIntegrations.find(c => c.type === typeVal).category,
            image: this.exIntegrations.find(c => c.type === typeVal).image,
          }

          switch (integrationModel.category) {
            case 'TRAFFIC':
              this.trafficIntegrations.push(integrationModel)
              break
            case 'ANALYTICS':
              this.analyticsIntegrations.push(integrationModel)
              break
            default:
              console.log('none category')
          }
        })
      })

    this.$http.get(`integration/get-main-analytic?projectId=${projectId}`)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        // TO-DO: NEED refactoring
        this.mainAnalytic = this.analyticsOptions.find(c => c.value === response.data)
      })

    this.$http.get(`background/history?projectId=${projectId}`)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        // TO-DO: NEED refactoring
        this.backgroundHistory = response.data
      })

    this.$http.get(`background/job?projectId=${projectId}`)
    // eslint-disable-next-line no-unused-vars
      .then(response => {
        // TO-DO: NEED refactoring
        this.job = response.data
        console.log(this.job, 'this.job')
        if (this.job != null
            && this.job !== ''
            && !this.job.isEnded) {
          this.clearInterval()
          this.collectingData = true
          this.isEndBackground = true
          this.counter = 10

          this.interval = setInterval(() => {
            if (this.counter < 50) {
              this.counter += 2
            }
          }, 5000)
        }
      })
  },
  methods: {
    removeProject() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите удалить проект?', {
          title: 'Удаление проекта',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Да, уверен',
          cancelTitle: 'Нет',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.get(`project/remove/${this.projectId}`)
            // eslint-disable-next-line no-unused-vars
              .then(response => {
                // eslint-disable-next-line
                    this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Проект успешно удален',
                    icon: 'SettingsIcon',
                    variant: 'success',
                  },
                })
                this.$router.push({
                  name: 'projects',
                })
              })
          } else {
            this.showLoading = false
          }
        })
    },
    onMessageReceived({ text, projectId, isEnded }) {
      this.message = {
        title: text,
        projectId,
        isEnded,
      }
    },
    percentagePlaceHolder(item) {
      if (item != null) {
        if (this.percentValues.includes(item)) {
          return '%'
        }

        if (this.rubValues.includes(item)) {
          return ' руб.'
        }
        return ''
      }
      return ''
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.tempOption.addTag(
        `${this.tempOption.option}=${this.indicatorValue}${this.percentagePlaceHolder(this.tempOption.option)}`,
      )
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-indicator')
        this.indicatorValue = null
        this.tempOption = null
        this.indicatorText = null
      })
    },
    onOptionClick({ option, addTag }) {
      this.tempOption = { option, addTag }
      this.indicatorText = option
      this.$bvModal.show('modal-indicator')
      this.search = ''
    },
    changeDatesRange(selectedDates) {
      this.changeDates = selectedDates
    },
    collectStats() {
      this.counter = 50
      let filterDates = []
      if (this.changeDates.length !== 0) {
        filterDates = this.changeDates
      } else {
        filterDates = this.rangeDataFilter
      }

      this.collectingData = true
      this.isEndBackground = false
      this.counter = 1
      // Simulate an async request
      this.clearInterval()

      this.interval = setInterval(() => {
        if (this.counter < 100) {
          this.counter += 1
        }
      }, 5000)

      this.$http.post('statistic/prepare', {
        projectId: this.projectId,
        startDate: +(new Date(filterDates[0])).yyyymmdd(),
        endDate: +(new Date(filterDates[1])).yyyymmdd(),
      })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.clearInterval()
          this.$nextTick(() => {
            // eslint-disable-next-line
            this.counter = 100
            this.isEndBackground = true
          })
        })
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    changeMainSystem() {
      this.$http.get(`integration/main-analytic?projectId=${this.projectId}&type=${this.mainAnalytic.value}`)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          const responseParse = response.data
          if (responseParse) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Основная система аналитики изменена',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Не удалось изменить основную систему аналитики',
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
    changeFields(val) {
      console.log('change')
      this.showProjectDetailLoading = true
      this.project.keyIndicators = this.tagsValue.join(',')

      const parsedKeys = []
      this.tagsValue.forEach(item => {
        const indicator = item.replace(/% ?/g, '')
          .replace(/руб./g, '')
          .split('=')
          .map(i => i.trim())

        parsedKeys.push(JSON.stringify({
          text: indicator[0],
          value: indicator[1],
        }))
      })

      this.project.keyIndicatorsValues = parsedKeys

      this.$store.dispatch('project/editProjectField', this.project).then(() => {
        switch (val) {
          case 'name':
            this.isShowNameRow = false
            this.showMessage('Имя проекта изменено')
            break
          case 'company':
            this.isShowCompanyRow = false
            this.showMessage('Название компании изменено')
            break
          case 'description':
            this.isShowDeatilRow = false
            this.showMessage('Описание проекта изменено')
            break
          case 'keyIndicators':
            this.isShowDeatilRow = false
            this.showMessage('Ключевые показатели проекта изменены')
            break
          default:
            console.log('none category')
        }
        this.$store.commit('project/UPDATE_PROJECT_BY_ID', this.project)
        this.showProjectDetailLoading = false
      })
      // this.$store.commit('project/UPDATE_ACTIVE_PROJECT_ID', project.id)
      // need change field
    },
    showMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    saveAvatar() {
      this.showProjectDetailLoading = true
      const { projectId } = this.$route.params
      const formData = new FormData()
      formData.append('file', this.$refs.refInputEl.files[0])
      this.$store.dispatch('project/uploadAvatar', { formData, projectId })
        .then(() => {
          this.avatarModel = null
          this.showProjectDetailLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Логотип изменен',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/tour.scss';
</style>

<style lang="scss" scoped>
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-inline::v-deep .list-inline-item {
  margin-top: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
