<template>
  <div>
    <b-button
      id="add-integration"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
      class="mb-2"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Добавить интеграцию</span>
    </b-button>

    <b-modal
      id="modal-xl"
      ok-only
      ok-title="Закрыть"
      centered
      size="xl"
      title="Поиск интеграций"
    >
      <section class="projects-list">
        <b-col cols="12">
          <div style="height: inherit">
            <section id="project-header">
              <div class="row">
                <div class="col-sm-12">
                  <div class="project-header-items" />
                </div>
              </div>
            </section>

            <div class="body-content-overlay" />

            <div class="project-searchbar mt-1">
              <b-row>
                <b-col cols="12">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="filters.q"
                      placeholder="Найти интеграцию"
                      class="search-product"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        icon="SearchIcon"
                        class="text-muted"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>

            <section
              :class="itemView"
              style="grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr!important;"
            >
              <b-card
                v-for="integration in exIntegrations"
                :key="integration.id"
                class="project-card"
                style="box-shadow: 2px 8px 10px 0 rgb(25 42 70 / 13%) !important"
                no-body
              >
                <div class="item-img text-center">
                  <b-img
                    :alt="`${integration.name}-${integration.id}`"
                    fluid
                    style="width: 50%;"
                    class="card-img-top"
                    :src="integration.image"
                  />
                </div>

                <b-card-body>
                  <h6 class="item-name">
                    {{ integration.name }}
                  </h6>
                  <b-card-text class="item-description">
                    {{ integration.description }}
                  </b-card-text>
                </b-card-body>

                <div class="item-options text-center">
                  <b-button
                    variant="primary"
                    tag="a"

                    class="btn-cart"
                    @click="addIntegrationInProject(integration)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span>
                      Добавить
                      <b-badge
                        v-if="integration.id === 5"
                        variant="success"
                      >
                        скоро в релизе
                      </b-badge>
                    </span>
                  </b-button>
                </div>
              </b-card>
            </section>

            <section>
              <b-row>
                <b-col cols="12" />
              </b-row>
            </section>
          </div>
        </b-col>
      </section>
    </b-modal>

    <app-collapse
      :type="collapseType"
    >
      <app-collapse-item
        :count="tempTrafficIntegrations.length"
        title="ТРАФИК"
      >
        <b-row v-if="tempTrafficIntegrations.length !== 0">
          <transition-group name="list">
            <div
              v-for="integration in tempTrafficIntegrations"
              :key="integration.id"
              class="list-item"
            >
              <div
                class="system-integration-card"
                style="margin-top: 10px; margin-bottom: 5px;"
              >
                <b-media
                  style="align-items: flex-start;"
                  no-body
                >
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="42"
                      :src="integration.image"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ integration.name }}
                    </h5>
                    <b-badge
                      :variant="statusVariant(integration.status)"
                      style="margin-top: 5px;"
                    >
                      {{ statusLabel(integration.status) }}
                    </b-badge>
                    <!-- <small style="margin-left: 10px;" class="text-muted">Обновлено 12м назад</small>-->
                  </b-media-body>
                  <b-button-group style="margin-top: 5px;">
                    <b-button
                      v-if="forBackend"
                      size="sm"
                      variant="gradient-primary"
                      class="btn-icon rounded-circle ml-2"
                      @click="redirectToConnection(integration.id)"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-left: 10px;"
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      @click="removeIntegration(integration)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-button-group>
                </b-media>
              </div>
            </div>
          </transition-group>
        </b-row>
        <b-row v-else>
          <transition>
            <div
              class="misc-inner p-2 p-sm-3 no-content"
            >
              <div class="w-100 text-center">
                <h2 class="mb-1">
                  Нет доступных интеграций
                </h2>
                <p class="mb-2">
                  Добавьте доступные интеграции из
                  <b-link v-b-modal.modal-xl>
                    списка
                  </b-link>
                </p>
              </div>
            </div>
          </transition>
        </b-row>
      </app-collapse-item>
      <app-collapse-item
        :count="tempAnalyticsIntegrations.length"
        title="АНАЛИТИКА"
      >
        <b-row v-if="tempAnalyticsIntegrations.length != 0">
          <transition-group name="list">
            <v-col
              v-for="integration in tempAnalyticsIntegrations"
              :key="integration.id"
              class="list-item"
              sm="2"
            >
              <div
                class="system-integration-card"
                style="margin-top: 10px; margin-bottom: 5px;"
              >
                <b-media
                  style="align-items: flex-start;"
                  no-body
                >
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="42"
                      :src="integration.image"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <h5 class="mb-0">
                      {{ integration.name }}
                    </h5>
                    <b-badge
                      :variant="statusVariant(integration.status)"
                      style="margin-top: 5px;"
                    >
                      {{ statusLabel(integration.status) }}
                    </b-badge>
                    <!-- <small style="margin-left: 10px;" class="text-muted">Обновлено 12м назад</small>-->
                  </b-media-body>
                  <b-button-group style="margin-top: 5px;">
                    <b-button
                      v-if="forBackend"
                      size="sm"
                      variant="gradient-primary"
                      class="btn-icon rounded-circle ml-2"
                      @click="redirectToConnection(integration.id)"
                    >
                      <feather-icon icon="SettingsIcon" />
                    </b-button>
                    <b-button
                      size="sm"
                      style="margin-left: 10px;"
                      variant="gradient-danger"
                      class="btn-icon rounded-circle"
                      @click="removeIntegration(integration)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </b-button-group>
                </b-media>
              </div>
            </v-col>
          </transition-group>
        </b-row>
        <b-row v-else>
          <div
            class="misc-inner p-2 p-sm-3 no-content"
          >
            <div class="w-100 text-center">
              <h2 class="mb-1">
                Нет доступных интеграций
              </h2>
              <p class="mb-2">
                Добавьте доступные интеграции из
                <b-link v-b-modal.modal-xl>
                  списка
                </b-link>
              </p>
            </div>
          </div>
        </b-row>
      </app-collapse-item>
      <app-collapse-item title="КОЛЛТРЕКИНГ">
        <div
          class="misc-inner p-2 p-sm-3 no-content"
        >
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Нет доступных интеграций
            </h2>
            <p class="mb-2">
              Добавьте доступные интеграции из
              <b-link v-b-modal.modal-xl>
                списка
              </b-link>
            </p>
          </div>
        </div>
      </app-collapse-item>
      <app-collapse-item title="CRM">
        <div
          class="misc-inner p-2 p-sm-3 no-content"
        >
          <div class="w-100 text-center">
            <h2 class="mb-1">
              Нет доступных интеграций
            </h2>
            <p class="mb-2">
              Добавьте доступные интеграции из
              <b-link v-b-modal.modal-xl>
                списка
              </b-link>
            </p>
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
    <app-tour :steps="steps" />
  </div>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  BButton,
  BButtonGroup,
  BLink,
  BRow,
  BAvatar,
  BMedia,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BMediaAside,
  BMediaBody,
  BCard,
  BCardText,
  BCardBody,
  BImg,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import AppTour from '@core/components/app-tour/AppTour.vue'
import {
  useShopFiltersSortingAndPagination,
  useShopRemoteData,
  useShopUi,
} from '@/views/apps/e-commerce/e-commerce-shop/useECommerceShop'
import { useEcommerceUi } from '@/views/apps/e-commerce/useEcommerce'

export default {
  name: 'IntegrationModule',
  components: {
    AppCollapse,
    AppTour,
    AppCollapseItem,
    BButtonGroup,
    BCardBody,
    BBadge,
    BInputGroup,
    BImg,
    BInputGroupAppend,
    BFormInput,
    BMedia,
    BCol,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BRow,
    BCard,
    BLink,
    BCardText,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    analyticsIntegrations: [],
    collapseType: {},
    exIntegrations: [],
    trafficIntegrations: [],
    projectId: null,
    forBackend: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      steps: [
        {
          target: '#add-integration',
          header: {
            title: 'Добавление интеграции',
          },
          content: 'Добавьте интеграцию в проект.',
        },
      ],
      statusMaps: {
        SUCCESS: 0,
        NONE: 1,
        ERROR: 2,
      },
      baseIndex: 0,
      typeMaps: {
        YANDEX_DIRECT: 0,
        YANDEX_METRIKA: 1,
        GOOGLE_ANALYTICS: 2,
        GOOGLE_ADWORDS: 3,
      },
      tempTrafficIntegrations: [],
      tempAnalyticsIntegrations: [],
    }
  },
  setup() {
    const {
      filters, filterOptions, sortBy, sortByOptions,
    } = useShopFiltersSortingAndPagination()

    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const {
      itemView, itemViewOptions, totalIntegrations,
    } = useShopUi()

    const { products, fetchProducts } = useShopRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const fetchShopProducts = () => {
      fetchProducts({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
      })
        .then(response => {
          products.value = response.data.products
          totalIntegrations.value = response.data.total
        })
    }

    fetchShopProducts()

    watch([filters, sortBy], () => {
      fetchShopProducts()
    }, {
      deep: true,
    })

    return {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,

      // useShopUi
      itemView,
      itemViewOptions,
      totalIntegrations,
      toggleProductInWishlist,
      handleCartActionClick,

      // useShopRemoteData
      products,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        SUCCESS: 'success',
        NONE: 'warning',
      }

      return status => statusColor[status]
    },
    statusLabel() {
      const statusLabel = {
        SUCCESS: 'Подключено',
        NONE: 'Не подключено',
      }

      return status => statusLabel[status]
    },
  },
  created() {
    this.tempTrafficIntegrations = this.trafficIntegrations
    this.tempAnalyticsIntegrations = this.analyticsIntegrations
  },
  methods: {
    redirectToConnection(val) {
      if (this.projectId != null) {
        this.$router.push({
          name: 'new-connection',
          params: {
            connectionId: val,
            projectId: this.projectId,
          },
        })
      }
    },
    removeIntegration(integration) {
      const { id } = integration

      switch (integration.category) {
        case 'TRAFFIC':
          this.tempTrafficIntegrations = this.tempTrafficIntegrations
            .filter(obj => obj.id !== integration.id)
          break
        case 'ANALYTICS':
          this.tempAnalyticsIntegrations = this.tempAnalyticsIntegrations
            .filter(obj => obj.id !== integration.id)
          break
        default:
          console.log('none category')
      }

      if (this.forBackend) {
        this.$http.get(`integration/remove?id=${id}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Интеграция удалена',
                icon: 'MinusIcon',
                variant: 'success',
              },
            })
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Интеграция удалена',
            icon: 'MinusIcon',
            variant: 'success',
          },
        })
      }
    },
    addIntegrationInProject(integration) {
      const integrationForBackend = {
        integrationType: this.typeMaps[integration.type],
        integrationStatus: 1,
        name: integration.name,
        projectId: this.projectId,
      }

      if (this.forBackend) {
        this.$http.post('integration/add-integration', integrationForBackend)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            const integrationModel = {
              id: response.data,
              status: 'NONE',
              name: integration.name,
              description: integration.description,
              type: integration.type,
              category: integration.category,
              image: integration.image,
            }

            switch (integration.category) {
              case 'TRAFFIC':
                this.tempTrafficIntegrations.push(integrationModel)
                break
              case 'ANALYTICS':
                this.tempAnalyticsIntegrations.push(integrationModel)
                break
              default:
                console.log('none category')
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Интеграция добавлена',
                icon: 'PlusIcon',
                variant: 'success',
              },
            })
          })
      } else {
        const integrationModel = {
          // eslint-disable-next-line no-plusplus
          id: this.baseIndex++,
          status: 'NONE',
          name: integration.name,
          description: integration.description,
          type: integration.type,
          category: integration.category,
          image: integration.image,
        }
        switch (integration.category) {
          case 'TRAFFIC':
            this.tempTrafficIntegrations.push(integrationModel)
            break
          case 'ANALYTICS':
            this.tempAnalyticsIntegrations.push(integrationModel)
            break
          default:
            console.log('none category')
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Интеграция добавлена',
            icon: 'PlusIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/projects-list.scss';
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
.system-integration-card {
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  margin-left: 20px;
  box-shadow: 2px 8px 10px 0 rgba(25, 42, 70, .13) !important;
  cursor: pointer;
  border-radius: 0.357rem !important;
}

.list-item {
  display: inline-block;
}

.no-content {
  margin: 0 auto;
}
</style>
<style lang="scss" scoped>

.card {
  box-shadow: none!important;
}

.card::v-deep .card-body {
  background-color: #f8f8f8;
}

.projects-list::v-deep .grid-view .project-card .item-img {
  display: table-header-group;
}
.projects-list::v-deep .grid-view .project-card .item-img {
  min-height: 10.85rem;
}

.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

</style>
